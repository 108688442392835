import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const SchoolDetails = () => {
  const { id: schoolId } = useParams();
  const [school, setSchool] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(`https://tms.up.school/api/get-schools/${schoolId}`);
        setSchool(response.data);
      } catch (error) {
        console.error('Error fetching school details:', error);
        setError('Failed to fetch school details.');
      }
    };

    if (schoolId) {
      fetchSchoolDetails();
    }
  }, [schoolId]);

  const renderJSON = (data) => {
    try {
      const parsed = typeof data === 'string' ? JSON.parse(data) : data;
  
      return (
        <div style={{
          backgroundColor: '#f8f9fa',
          padding: '12px',
          borderRadius: '6px',
          fontFamily: 'monospace',
          fontSize: '14px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          border: '1px solid #ddd',
          overflowX: 'auto'
        }}>
          {Object.entries(parsed).map(([key, value]) => (
            <div key={key}>
              <strong>{key}:</strong>{" "}
              {typeof value === 'object' && value !== null
                ? <pre style={{ margin: '4px 0 12px', paddingLeft: '16px' }}>{JSON.stringify(value, null, 2)}</pre>
                : <span>{String(value)}</span>}
            </div>
          ))}
        </div>
      );
    } catch (error) {
      return <p style={{ color: 'red' }}>Invalid JSON format</p>;
    }
  };
  

  if (error) return <div>{error}</div>;
  if (!school) return <div>Loading...</div>;

  return (
    <div style={{ padding: '1rem' }}>
      <h2>{school.school_name}</h2>
      <p><strong>City:</strong> {school.city || 'N/A'}</p>
      <p><strong>Phone:</strong> {school.phone_no || 'N/A'}</p>
      <p><strong>Board:</strong> {Array.isArray(school.school_board) ? school.school_board.join(', ') : school.school_board}</p>
      <p><strong>Status:</strong> {school.school_status || 'N/A'}</p>

      {school.school_logoURL && (
        <div style={{ margin: '1rem 0' }}>
          <img src={school.school_logoURL} alt="School Logo" width="120" />
        </div>
      )}

      <hr />
      <h4 style={{ color: '#673ab7' }}>School Subscription Features</h4>
      {school.school_subscribtion_feature
        ? renderJSON(school.school_subscribtion_feature)
        : <p>No subscription features available.</p>}

      <h4 style={{ color: 'green' }}>Pre-Quiz Configurations</h4>
      {school.pre_quiz_config
        ? renderJSON(school.pre_quiz_config)
        : <p>No pre-quiz config available.</p>}

      <h4 style={{ color: '#ff9800' }}>Test Configurations</h4>
      {school.test_config
        ? renderJSON(school.test_config)
        : <p>No test config available.</p>}

      <h4 style={{ color: '#f44336' }}>Post-Quiz Configurations</h4>
      {school.post_quiz_config
        ? renderJSON(school.post_quiz_config)
        : <p>No post-quiz config available.</p>}

      <hr />
      <h4>General Information</h4>
      <p><strong>LC School Name:</strong> {school.lc_school_name || 'N/A'}</p>
      <p><strong>GST No:</strong> {school.GST_no || 'N/A'}</p>
      <p><strong>Label:</strong> {school.label || 'N/A'}</p>
      <p><strong>Last Updated:</strong> {school.updated_ts ? new Date(school.updated_ts).toLocaleString() : 'N/A'}</p>
      <p><strong>Created At:</strong> {school.created_ts ? new Date(school.created_ts).toLocaleString() : 'N/A'}</p>
    </div>
  );
};

export default SchoolDetails;
