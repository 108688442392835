import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Outlet, NavLink } from 'react-router-dom';
import axios from 'axios';
import '../styles.css';

const EditSchool = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [school, setSchool] = useState({
    school_name: '',
    phone_no: '',
    school_board: '',
    city: '',
    pincode: '',
  });
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSchoolDetails = async () => {
      try {
        const response = await axios.get(`https://tms.up.school/api/schools/${id}`);
        setSchool(response.data);
      } catch (error) {
        console.error('Error fetching school details:', error);
        setError('Failed to fetch school details.');
      }
    };

    if (id) {
      fetchSchoolDetails();
    }
  }, [id]);

  return (
    <div className="edit-school-container">
      {error && <p className="error">{error}</p>}
      <div className="school-header">
  <h2>{school.school_name}</h2>
  {(school.city || school.pincode) && (
    <p>{school.city}{school.city && school.pincode ? ' - ' : ''}{school.pincode}</p>
  )}
  {school.phone_no && <p>Phone: {school.phone_no}</p>}
  {school.school_logoURL && (
    <img src={school.school_logoURL} alt="School Logo" style={{ width: '100px' }} />
  )}
</div>

      <div className="tabs">
        <NavLink to={`/edit-school/${id}/details`} className="tab-button">School Details</NavLink>
        <NavLink to={`/edit-school/${id}/timetable`} className="tab-button">Timetable Settings</NavLink>
        <NavLink to={`/edit-school/${id}/calendar`} className="tab-button">School Calendar</NavLink>
        <NavLink to={`/edit-school/${id}/classes`} className="tab-button">Class Info</NavLink>
        <NavLink to={`/edit-school/${id}/members`} className="tab-button">Members</NavLink>
      </div>

      <Outlet context={{ schoolId: id }} />
    </div>
  );
};

export default EditSchool;
