import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FetchedSchools = () => {
    const [schools, setSchools] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSchools = async () => {
            try {
                const response = await axios.get('https://tms.up.school/api/get-schools');
                setSchools(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching schools:", error);
                setError("Failed to load schools.");
                setLoading(false);
            }
        };

        fetchSchools();
    }, []);

    if (loading) return <div className="text-center"><Spinner animation="border" /></div>;
    if (error) return <p className="text-danger">{error}</p>;

    return (
        <div className="container mt-4">
            <h2 className="mb-4">Fetched Schools</h2>
            <Table bordered hover responsive>
                <thead className="table-dark">
                    <tr>
                        <th>School Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {schools.map((school) => (
                        <tr key={school.school_id}>
                            <td>{school.school_name || "N/A"}</td>
                            <td>
                                <Link to={`/edit-school/${school.school_id}/details`}>
                                    <Button variant="primary" size="sm">Edit</Button>
                                </Link>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default FetchedSchools;
