import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useOutletContext, useNavigate } from 'react-router-dom';

const ClassInfo = () => {
  const { schoolId, schoolName } = useOutletContext();
  const navigate = useNavigate();
  const [fetchedClassData, setFetchedClassData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [editDates, setEditDates] = useState({});

  const fetchStoredClassInfo = async () => {
    try {
      const response = await axios.get(
        `https://tms.up.school/api/classinfo/get-fetched-classinfo/${schoolId}`
      );
      return response.data;
    } catch (error) {
      console.error('Error fetching stored class info:', error);
      return [];
    }
  };

  const fetchAndSaveClassInfo = async () => {
    setLoading(true);
    try {
      const existingData = await fetchStoredClassInfo();
      if (existingData.length > 0) {
        setFetchedClassData(existingData);
        setLoading(false);
        return;
      }

      await axios.get(
        `https://tms.up.school/api/classinfo/fetch-class-section-subject/${schoolId}`
      );

      const updatedData = await fetchStoredClassInfo();
      setFetchedClassData(updatedData);
    } catch (error) {
      console.error('Error fetching/saving class info:', error);
      setError('Something went wrong while fetching class info');
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (id, field, value) => {
    setEditDates((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        [field]: value,
      },
    }));
  };

  const handleSaveDates = async (id) => {
    const data = editDates[id];
    if (!data) return;

    try {
      await axios.put(`https://tms.up.school/api/classinfo/update-classinfo-dates/${id}`, data);
      const updatedData = await fetchStoredClassInfo();
      setFetchedClassData(updatedData);
      setEditDates((prev) => {
        const newEdit = { ...prev };
        delete newEdit[id];
        return newEdit;
      });
    } catch (error) {
      console.error('Failed to save dates:', error);
      alert('Failed to update dates. Please try again.');
    }
  };

  useEffect(() => {
    setFetchedClassData([]); 
  }, [schoolId]);
  

  return (
    <div>
      <h2>Fetched Class Information</h2>

      <button onClick={fetchAndSaveClassInfo} disabled={loading}>
        {loading ? 'Fetching...' : 'Fetch Class Info'}
      </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}

      {fetchedClassData.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Class Name</th>
              <th>Section Name</th>
              <th>Subject</th>
              <th>Board</th>
              <th>Academic Start</th>
              <th>Academic End</th>
              <th>Revision Start</th>
              <th>Revision End</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fetchedClassData.map((info) => (
              <tr key={info.id}>
                <td>{info.upschool_class_name}</td>
                <td>{info.section_name}</td>
                <td>{info.subject_title}</td>
                <td>{info.board}</td>

                {['academic_start_date', 'academic_end_date', 'revision_start_date', 'revision_end_date'].map((field) => (
                  <td key={field}>
                    <input
                      type="date"
                      value={
                        editDates[info.id]?.[field] ??
                        (info[field] ? info[field].slice(0, 10) : '')
                      }
                      onChange={(e) => handleDateChange(info.id, field, e.target.value)}
                    />
                  </td>
                ))}

                <td>
                  <button
                    onClick={() =>
                      navigate(`/fetched/${info.id}/sessions/manage`, {
                        state: {
                          fetchedClassInfoId: info.id,
                          schoolId,
                          schoolName,
                          className: info.upschool_class_name,
                          sectionName: info.section_name,
                          subjectName: info.subject_title,
                          board: info.board,
                        },
                      })
                    }
                  >
                    Manage Sessions
                  </button>
                  <br />
                  <button
                    style={{ marginTop: '5px' }}
                    onClick={() => handleSaveDates(info.id)}
                  >
                    Save Dates
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No class info found. Click the button above to fetch it.</p>
      )}
    </div>
  );
};

export default ClassInfo;
