import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';

const SessionManagement = () => {
  const location = useLocation();

  // Extract props passed via state
  const {
    fetchedClassInfoId,
    schoolId = 'School ID Not Available',
    schoolName = 'School Name Not Available',
    className = 'Class Name Not Available',
    sectionName = 'Section Name Not Available',
    subjectName = 'Subject Name Not Available',
    board: boardName = 'Board Not Available',
  } = location.state || {};

  const [sessions, setSessions] = useState([]);
  const [editingSessionId, setEditingSessionId] = useState(null);
  const [editingNumberOfSessions, setEditingNumberOfSessions] = useState('');
  const [editingPriorityNumber, setEditingPriorityNumber] = useState('');
  const [selectedSessionIds, setSelectedSessionIds] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // ✅ Fetch sessions
  const fetchSessions = async () => {
    setIsLoading(true);
    setError('');
    try {
      const response = await axios.get(
        `https://tms.up.school/api/fetched/${fetchedClassInfoId}/sessions`
      );
      const fetchedSessions = response.data.sessions || [];
      setSessions(fetchedSessions);
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setError('Failed to fetch sessions. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fetchedClassInfoId) {
      console.log("Using fetchedClassInfoId:", fetchedClassInfoId); 
      fetchSessions();
    }
  }, [fetchedClassInfoId]);
  

  const startEditing = (session) => {
    setEditingSessionId(session.id);
    setEditingNumberOfSessions(session.numberOfSessions);
    setEditingPriorityNumber(session.priorityNumber);
  };

  const handleSessionUpdate = async (sessionId) => {
    try {
      await axios.put(`https://tms.up.school/api/sessions/${sessionId}`, {
        numberOfSessions: editingNumberOfSessions,
        priorityNumber: editingPriorityNumber,
      });
      setEditingSessionId(null);
      fetchSessions();
    } catch (error) {
      console.error('Error updating session:', error);
      setError('Failed to update session.');
    }
  };

  const handleSessionDelete = async (sessionId) => {
    try {
      await axios.delete(`https://tms.up.school/api/sessions/${sessionId}`);
      fetchSessions();
    } catch (error) {
      console.error('Error deleting session:', error);
      setError('Failed to delete session.');
    }
  };

  const handleBulkDelete = async () => {
    if (!selectedSessionIds.length) {
      setError('Select at least one session to delete.');
      return;
    }

    try {
      await axios.post(`https://tms.up.school/api/sessions/bulk-delete`, {
        sessionIds: selectedSessionIds,
      });
      setSelectedSessionIds([]);
      fetchSessions();
    } catch (error) {
      console.error('Error bulk deleting:', error);
      setError('Bulk delete failed.');
    }
  };

  const toggleSelection = (sessionId) => {
    setSelectedSessionIds((prev) =>
      prev.includes(sessionId)
        ? prev.filter((id) => id !== sessionId)
        : [...prev, sessionId]
    );
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.elements.file.files[0];
    if (!file) {
      setError('Please select a file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    setIsLoading(true);
    setError('');
    try {
      await axios.post(
        `https://tms.up.school/api/fetched/${fetchedClassInfoId}/sessions/upload`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      fetchSessions();
    } catch (error) {
      console.error('Error uploading:', error);
      setError('Upload failed.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Session Management</h2>

      {/* Info */}
      <p>
        <strong>School:</strong> {schoolName} | <strong>Class:</strong> {className} |{' '}
        <strong>Section:</strong> {sectionName} | <strong>Subject:</strong> {subjectName} |{' '}
        <strong>Board:</strong> {Array.isArray(JSON.parse(boardName)) ? JSON.parse(boardName).join(", ") : boardName}
        </p>

      {error && <div style={{ color: 'red' }}>{error}</div>}
      {isLoading && <p>Loading...</p>}

      {/* Upload Excel */}
      <form onSubmit={handleFileUpload}>
        <input type="file" name="file" accept=".xlsx, .xls" required />
        <button type="submit">Upload</button>
      </form>

      {/* Bulk delete */}
      <button onClick={handleBulkDelete} disabled={!selectedSessionIds.length}>
        Delete Selected
      </button>

      {/* Session Table */}
      <table border="1" cellPadding="8" style={{ marginTop: '20px', width: '100%' }}>
        <thead>
          <tr>
            <th>
              <input
                type="checkbox"
                onChange={(e) =>
                  setSelectedSessionIds(
                    e.target.checked ? sessions.map((s) => s.id) : []
                  )
                }
                checked={
                  selectedSessionIds.length === sessions.length &&
                  sessions.length > 0
                }
              />
            </th>
            <th>Unit</th>
            <th>Chapter</th>
            <th>Sessions</th>
            <th>Priority</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
        {Array.isArray(sessions) && sessions.length > 0 ? (
            sessions.map((s) => (
              <tr key={s.id}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedSessionIds.includes(s.id)}
                    onChange={() => toggleSelection(s.id)}
                  />
                </td>
                <td>{s.unitName}</td>
                <td>{s.chapterName}</td>
                <td>
                  {editingSessionId === s.id ? (
                    <input
                      type="number"
                      value={editingNumberOfSessions}
                      onChange={(e) => setEditingNumberOfSessions(e.target.value)}
                    />
                  ) : (
                    s.numberOfSessions
                  )}
                </td>
                <td>
                  {editingSessionId === s.id ? (
                    <input
                      type="number"
                      value={editingPriorityNumber}
                      onChange={(e) => setEditingPriorityNumber(e.target.value)}
                    />
                  ) : (
                    s.priorityNumber
                  )}
                </td>
                <td>
                  {editingSessionId === s.id ? (
                    <>
                      <button onClick={() => handleSessionUpdate(s.id)}>Save</button>
                      <button onClick={() => setEditingSessionId(null)}>Cancel</button>
                    </>
                  ) : (
                    <>
                      <button onClick={() => startEditing(s)}>Edit</button>
                      <button onClick={() => handleSessionDelete(s.id)}>Delete</button>
                      <Link
                        to={`/sessions/${s.id}/sessionPlans`}
                        state={{
                          fetchedClassInfoId,
                          sessionId: s.id,
                          schoolId,
                          schoolName,
                          className,
                          sectionName,
                          subjectName,
                          board: boardName,
                          chapterName: s.chapterName,
                          unitName: s.unitName,
                        }}
                      >
                        <button>Session Plan</button>
                      </Link>
                    </>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" style={{ textAlign: 'center' }}>
                No sessions available.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SessionManagement;
